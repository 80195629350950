import React, {memo, useEffect, useRef, useState} from "react";
import {motion, useAnimation, useMotionValue, useTransform} from "framer-motion";
import useVH from "react-viewport-height";
import './style.css';

import ScaledImage from "../../../components/layouts/ScaledImage";

import nude_fluffy from "../../../assets/img/02_scan/scan_nude@4x.png";
import shadow_fluffy from "../../../assets/img/03_play/play_shaddow.png";
import flower_band from "../../../assets/img/03_play/play_flowerband.png";
import play_star from "../../../assets/img/03_play/play_stars.png";
import thankful_face from "../../../assets/img/05_faces/thankful.svg";
import eyes_closed from "../../../assets/img/05_faces/happy.svg";
import eyes_big from "../../../assets/img/05_faces/scared.svg";
import smile_face from "../../../assets/img/05_faces/smile.svg";
import farting_face from "../../../assets/img/05_faces/embarrassing.svg";

import Sound from "../../../components/sounds/Sound";
import click from "../../../assets/sounds/click.mp3";
import angrySoundClip from "../../../assets/sounds/angry_fluffy.mp3";
import back_button from "../../../assets/img/03_play/play_back.png";
import bg01 from "../../../assets/img/03_play/play_bg-01.png";
import fart from '../../../assets/sounds/fart.mp3';
import ScaledMotionImage from "../../../components/layouts/ScaledMotionImage";
import useWindowDimensions from "../../../hooks/useWindowDimensions";
import {useNavigate} from "react-router-dom";
import fluffy from "../index";

type Props = {
    scaleFactor: number;
    fluffyName: string
};

function NudeFluffy({scaleFactor, fluffyName}: Props) {

    const vh = useVH();
    const fartSound = new Sound(fart);
    const angrySound = new Sound(angrySoundClip);
    const fluffyFlowerBandRef = useRef<HTMLDivElement>(null);
    const flowerBandMainDiv = useRef<HTMLDivElement>(null);
    const initialFlowerBand = useRef<HTMLDivElement>(null);
    const fluffyFlowerBandRect = useRef<HTMLDivElement>(null);
    const fluffyContainer = useRef<HTMLDivElement>(null);
    const animationControl = useAnimation();
    const star_01_animation = useAnimation();
    const star_02_animation = useAnimation();
    const flower_band_animation = useAnimation();
    const flower_band_animation_2 = useAnimation();
    const shadow_animation = useAnimation();
    const heart_animation = useAnimation();
    const thankful_animation = useAnimation();
    const smile_face_animation = useAnimation();
    const eyes_big_animation = useAnimation();
    const eyes_closed_animation = useAnimation();
    const y_fluffy = useMotionValue(0);
    const x_fluffy = useMotionValue(0);
    const y_flowerBand = useMotionValue(0);
    const opacity_shadow = useTransform(y_fluffy, [0, -100], [1, 0]);
    const scale_shadow = useTransform(y_fluffy, [0, -100], [1, 0]);

    const farting_face_animation = useAnimation();

    const {windowHeight, windowWidth} = useWindowDimensions();

    const FLUFFY_SIZE = 0.7;
    const clickSound = new Sound(click);
    const navigate = useNavigate();
    let [tap_timeStamp, setTapTime] = useState(0);
    let [tapCount, setTapCount] = useState(0);
    let [isTaped, setTaped] = useState(1);
    let [isJumping, setIsJumping] = useState(0);
    let [isFlowerBandSet, setFlowerBand] = useState(0);
    let [isTurnRight, setTurnRight] = useState(0);
    let [whichExpression, setWhichExpression] = useState('');

    const FARTING_FACE = "fart";
    const SMILE_FACE = "smile";
    const THANKFUL_FACE = "thankful";
    const EYES_BIG = "eyes_big";
    const EYES_CLOSED = "eyes_closed";


    /*
    * TODO: Animation
    *     1. code optimization: variants and animation control name
    *     2. fix farting face flickering issue [fixed]
    *     3. Add shadow during jumping. [done]
    *     4. Remove background focus color from the flower band 2
    *     5. Add changing expression function [done]
    * */
    const nude_scan_variants = {
        visible: {
            opacity: 1,
            transition: {
                duration: 1.5
            },
            scale: FLUFFY_SIZE
        },
        hidden: {opacity: 0, scale: 1.3},
        rotateRight: {
            rotate: 10,
            transition: {
                duration: 0.3
            }
        },
        rotateLeft: {
            rotate: 0,
            transition: {
                duration: 0.3
            }
        }
    }

    const star_01_variants = {
        visible: {
            opacity: 1,
            transition: {
                type: "reverse",
                repeat: Infinity,
                duration: 1.5
            }
        },
        hidden: {opacity: 0},
    }

    let flower_band_variants = {
        hidden: {opacity: 1},
    }

    let flower_band_variants_2 = {
        hidden: {opacity: 0}
    }

    const heart_variants = {
        visible: {
            opacity: 1,
            scale: 1.3,
            transition: {
                ease: "linear",
                duration: 0.5,
            }
        },
        hidden: {
            opacity: 0,
            transition: {
                ease: "easeOut",
                duration: 0.5,
            },
            scale: 0.5
        },
        initial: {
            opacity: 0,
            transition: {
                ease: "easeOut",
                duration: 0.5,
            },
            scale: 1.3
        }
    }

    const thankful_variants = {
        visible: {
            opacity: 1, transition: {duration: 0}
        },
        hidden: {
            opacity: 0,
            transition: {
                duration: 0,
            }
        },
    }

    const smile_face_variants = {
        visible: {opacity: 1, transition: {duration: 0,}},
        hidden: {opacity: 0, transition: {duration: 0}},
    }
    const default_variants = {
        hidden: {opacity: 0, transition: {duration: 0}},
        visible: {opacity: 1, transition: {duration: 0}}
    }
    const eyes_big_variants = {
        hidden: {opacity: 0, transition: {duration: 0}},
        visible: {opacity: 1, transition: {duration: 0}}
    }
    const farting_face_variants = {
        hidden: {opacity: 0, transition: {duration: 0}},
        visible: {opacity: 1, transition: {duration: 0}}
    }

    // calling animation controllers
    animationControl.start("visible");
    star_01_animation.start("visible");


    async function turnLeft() {
        await animationControl.start("rotateLeft");
        await changeFaceExpression(SMILE_FACE, true);
    }

    async function turnRight() {
        await animationControl.start("rotateRight");
        await changeFaceExpression(THANKFUL_FACE, true);
    }

    async function thankfulFaceAnimation() {
        //get the height width of flower band situated inside fluffy
        if (!isFlowerBandSet && fluffyFlowerBandRect.current !== null && initialFlowerBand.current !== null && fluffyContainer.current !== null && flowerBandMainDiv.current != null) {
            star_01_animation.start("hidden");
            initialFlowerBand.current.style.zIndex = "100";

            let bandLeft = fluffyFlowerBandRect.current.getBoundingClientRect().left;
            let bandRight = fluffyFlowerBandRect.current.getBoundingClientRect().right;
            let bandTop = fluffyFlowerBandRect.current.getBoundingClientRect().top;
            let bandBottom = fluffyFlowerBandRect.current.getBoundingClientRect().bottom;

            await flower_band_animation.start({
                top: bandTop,
                left: bandLeft,
                right: bandRight,
                bottom: bandBottom,
                pointerEvents: "none",
                transition: {
                    type: "spring",
                    stiffness: 70,
                    duration: 0.8,
                }
            });
            await flower_band_animation_2.start({opacity: 1, zIndex: "100", pointerEvents:"auto", transition: {duration: 0}});

            flower_band_animation.start({opacity: 0, transition:{duration: 0}});

            setTimeout(()=>{
                if(flowerBandMainDiv.current !== null){
                    flower_band_animation.start({
                        pointerEvents: "none",
                        top: flowerBandMainDiv.current.offsetTop,
                        left: flowerBandMainDiv.current.offsetLeft - 100,
                        transition: {
                            duration: 0,
                        }
                    });
                }
            }, 50);
        }

        await heart_animation.start("visible");
        await heart_animation.start("initial");
        await heart_animation.start("hidden");

        await turnRight();
        isTurnRight = 1;
        isFlowerBandSet = 1;
    }

    function stopAllExpression() {
        //console.log("stop exp: " + whichExpression);

        if (whichExpression === SMILE_FACE) {
            smile_face_animation.start({opacity: 0, transition: {duration: 0}});
        } else if (whichExpression === FARTING_FACE) {
            farting_face_animation.start({opacity: 0, transition: {duration: 0}});
        } else if (whichExpression === THANKFUL_FACE) {
            thankful_animation.start({opacity: 0, transition: {duration: 0}});
        } else if (whichExpression === EYES_BIG) {
            eyes_big_animation.start({opacity: 0, transition: {duration: 0}});
        } else if (whichExpression === EYES_CLOSED) {
            eyes_closed_animation.start({opacity: 0, transition: {duration: 0}});
        } else {
            smile_face_animation.start({opacity: 0, transition: {duration: 0}});
            eyes_big_animation.start({opacity: 0, transition: {duration: 0}});
            farting_face_animation.start({opacity: 0, transition: {duration: 0}});
            thankful_animation.start({opacity: 0, transition: {duration: 0}});
            eyes_closed_animation.start({opacity: 0, transition: {duration: 0}});
        }
    }

    // change face expression..
    async function changeFaceExpression(expression: string, wait: boolean) {
        stopAllExpression();

        //console.log("which expression: " + expression);
        if (expression === SMILE_FACE) {
            whichExpression = SMILE_FACE;
            if (wait) {
                await smile_face_animation.start({opacity: 1, transition: {duration: 0}});
            } else {
                smile_face_animation.start({opacity: 1, transition: {duration: 0}});
            }
        } else if (expression === FARTING_FACE) {
            whichExpression = FARTING_FACE;
            if (wait) {
                await farting_face_animation.start({opacity: 1, transition: {duration: 0}});
            } else {
                farting_face_animation.start({opacity: 1, transition: {duration: 0}});
            }
        } else if (expression === THANKFUL_FACE) {
            whichExpression = THANKFUL_FACE;
            if (wait) {
                await thankful_animation.start({opacity: 1, transition: {duration: 0}});
            } else {
                thankful_animation.start({opacity: 1, transition: {duration: 0}});
            }
        } else if (expression === EYES_BIG) {
            whichExpression = EYES_BIG;
            if (wait) {
                await eyes_big_animation.start({opacity: 1, transition: {duration: 0}});
            } else {
                eyes_big_animation.start({opacity: 1, transition: {duration: 0}});
            }
        } else if (expression === EYES_CLOSED) {
            whichExpression = EYES_CLOSED;
            if (wait) {
                await eyes_closed_animation.start({opacity: 1, transition: {duration: 0}});
            } else {
                eyes_closed_animation.start({opacity: 1, transition: {duration: 0}});
            }
        }
    }

    async function stopJumpingAnimation() {
        setIsJumping(0);
        //await animationControl.stop();
        changeFaceExpression(SMILE_FACE, false);
        await animationControl.start({
            y: 0,
            transition: {
                duration: 0.3,
                type: "spring",
                stiffness: 150
            },
            scale: 0.7
        });
    }

    useEffect(() => {
        function updateFacesOnJumping() {
            if (isJumping) {
                const current_y = y_fluffy.get();
                if (current_y < -50) {
                    if (isFlowerBandSet) {
                        changeFaceExpression(EYES_CLOSED, false);
                    } else {
                        changeFaceExpression(EYES_BIG, false);
                    }
                }
                if (current_y > -50) {
                    changeFaceExpression(SMILE_FACE, false);
                }
            }
        }

        const unsubscribeY = y_fluffy.onChange(updateFacesOnJumping);

        return () => {
            unsubscribeY();
        }
    }, []);


    async function startJumpingAnimation() {
        await turnLeft();
        isJumping = 1;
        await animationControl.start({
            scale: [0.7, 0.3, 0.7],
            y: [0, -(windowHeight / 2), 0],
            transition: {
                repeat: Infinity,
                duration: 1.5,
                type: "spring",
                stiffness: 100,
                damping: 10,
            }
        });
    }

    // Event listeners ..
    function hideContextMenu(e: any) {
        e.preventDefault();
    }

    function handleBackNavigation(e: any) {
        e.preventDefault();
        clickSound.play();
        setTimeout(() => {
            navigate("/", {replace: true});
        }, 200);
    }

    async function handleOnTap(event: any, panInfo: any) {
        event.preventDefault();
        let compare = Math.abs(tap_timeStamp - event.timeStamp);
        if(compare < 300){
            tapCount = tapCount + 1;
        }else{
            tapCount = 1;
        }
        tap_timeStamp = event.timeStamp;

        if (tapCount > 4) {
            fartSound.play();
            changeFaceExpression(FARTING_FACE, true);
            await setTimeout(async () => {
                if (isJumping === 1) {
                    changeFaceExpression(EYES_CLOSED, false);
                } else {
                    changeFaceExpression(SMILE_FACE, false);
                }
            }, 1500);
        }
        else if(tapCount === 2){
            if (isJumping === 1) {
                stopJumpingAnimation();
            } else {
                startJumpingAnimation();
            }
        }

        if (isJumping === 1) {
            isJumping = 0;
            await stopJumpingAnimation();
        }

        if (isTurnRight === 1) {
            isTurnRight = 0;
            await turnLeft();
        }

    }

    async function handleDragEvent(e: any, info: any) {
        e.preventDefault();
        //eyes_big_animation.start({opacity: 1, transition: {duration: 0}});
    }

    async function handleDragEventStop(e: any, info: any) {
        e.preventDefault();
        changeFaceExpression(SMILE_FACE, false);
    }

    async function handleDragEventStart(e: any, info: any) {
        e.preventDefault();
        angrySound.play();
        changeFaceExpression(EYES_BIG, false);
    }

    async function handleFlowerEvent(e: any) {
        e.preventDefault();
        await thankfulFaceAnimation();
    }

    async function handleFlowerEvent02(e: any) {
        e.preventDefault();
        if (isFlowerBandSet === 1) {
            await thankfulFaceAnimation();
        }
    }

    async function removeFlowerBand(e: any) {
        e.preventDefault();
        if(isFlowerBandSet){
            if(flowerBandMainDiv.current != null && fluffyFlowerBandRect.current !== null && initialFlowerBand.current !== null){
                await flower_band_animation_2.start({
                    top: flowerBandMainDiv.current.getBoundingClientRect().top,
                    right: flowerBandMainDiv.current.getBoundingClientRect().right,
                    opacity: 0,
                    transition: {
                        duration: 2
                    }
                });

                flower_band_animation.start({opacity: 1, zIndex: "100", pointerEvents:"auto", transition: {duration: 0}});
                flower_band_animation_2.start({opacity: 0, pointerEvents:"none", transition: {duration: 0}});

                star_01_animation.start("visible");
                isFlowerBandSet = 0;

                setTimeout(()=>{
                    if(fluffyFlowerBandRef.current !== null){
                        flower_band_animation_2.start({
                            pointerEvents: "none",
                            top: fluffyFlowerBandRef.current.offsetTop,
                            left: fluffyFlowerBandRef.current.offsetLeft,
                            transition: {
                                duration: 0,
                            }
                        });
                    }
                }, 2000);
            }
        }
    }

    // render function
    return (
        <motion.div
            animate={{
                opacity: 1,
                transition: {
                    duration: 1,
                    ease: "easeIn"
                }
            }}
            initial={{
                opacity: 0.2
            }}
            className="flex flex-col items-center justify-between text-center select-none"
            style={{
                backgroundImage: `url(${bg01})`,
                height: `${100 * vh}px`,
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center"
            }}>
            {/*
             flower band container
            */}
            <div ref={flowerBandMainDiv} className="absolute select-none" style={{
                right: 80 * scaleFactor,
                top: 80 * scaleFactor,
                WebkitTapHighlightColor: "transparent"
            }}>
                <motion.div
                    className="absolute flower_star_01 select-none"
                    style={{
                        top: -50 * scaleFactor,
                        right: -50 * scaleFactor,
                        WebkitTapHighlightColor: "transparent"
                    }}
                    variants={star_01_variants}
                    animate={star_01_animation}
                    initial="hidden"
                >
                    <ScaledImage src={play_star} originalWidth={260} id="star_01" alt="" className="touch-none select-none self-center"/>
                </motion.div>
            </div>
            <motion.div
                ref={initialFlowerBand}
                className="absolute z-10 noselect"
                style={{
                    right: 80 * scaleFactor,
                    top: 80 * scaleFactor,
                    WebkitTapHighlightColor: "transparent"
                }}
                onClick={(e) => handleFlowerEvent(e)}
                variants={flower_band_variants}
                animate={flower_band_animation}
                initial="hidden"
            >
                <ScaledImage src={flower_band} originalWidth={200} id="flower_band" alt=""
                             className="self-center touch-none select-none"/>

            </motion.div>

            {/* back navigation */}
            <motion.div
                className="absolute z-10"
                style={{
                    left: 50 * scaleFactor,
                    top: 50 * scaleFactor,
                    WebkitTapHighlightColor: "transparent"
                }}
                onClick={(e) => handleBackNavigation(e)}
                whileTap={{scale: 0.8}}
            >
                <ScaledImage src={back_button} id="backButton" alt="back-button"
                             className="self-center touch-none select-none"/>

            </motion.div>

            {/*
                     Fluffy
                    */}
            <motion.div
                ref={fluffyContainer}
                className="absolute flex justify-center w-auto h-auto z-20 select-none"
                style={{
                    bottom: 50 * scaleFactor,
                    y: y_fluffy,
                    x: x_fluffy
                }}
                drag
                dragConstraints={{left: -windowWidth / 2 + 50, right: windowWidth / 2 - 50, top: 0, bottom: 0}}
                dragTransition={{bounceStiffness: 100, bounceDamping: 10}}
                dragElastic={0.8}
                variants={nude_scan_variants}
                animate={animationControl}
                onTap={(e, info) => handleOnTap(e, info)}
                onDrag={(e, info) => handleDragEvent(e, info)}
                onDragEnd={(e, info) => handleDragEventStop(e, info)}
                onDragStart={(e, info) => handleDragEventStart(e, info)}
                onContextMenu={(e) => hideContextMenu(e)}
                initial="hidden"
            >

                { /*flower band animation exchange */}
                <div  ref={fluffyFlowerBandRef} className="absolute touch-none"  style={{left: "35%", top: 20 * scaleFactor,}}> </div>
                <motion.div
                    ref={fluffyFlowerBandRect}
                    id="fluffyFlowerBand"
                    className="absolute z-50 bg-transparent select-none"
                    variants={flower_band_variants_2}
                    animate={flower_band_animation_2}
                    style={{
                        left: "35%",
                        top: 20 * scaleFactor,
                        WebkitTapHighlightColor: "transparent"
                    }}
                    initial="hidden"
                    drag
                    dragConstraints={{left: 0, right: 0, top: 0, bottom: 0}}
                    dragElastic={1}
                    onClick={(e) => handleFlowerEvent02(e)}
                    onDragEnd={(e) => removeFlowerBand(e)}
                    onContextMenu={(e) => hideContextMenu(e)}
                >
                    <ScaledImage src={flower_band} originalWidth={280} id="flower_band" alt=""
                                 className="self-center touch-none select-none"/>
                </motion.div>

                <motion.svg
                    variants={heart_variants}
                    animate={heart_animation}
                    initial="hidden"
                    className="heart"
                    viewBox="0 0 32 29.6">
                    <path d="M23.6,0c-3.4,0-6.3,2.7-7.6,5.6C14.7,2.7,11.8,0,8.4,0C3.8,0,0,3.8,0,8.4c0,9.4,9.5,11.9,16,21.2
	c6.1-9.3,16-12.1,16-21.2C32,3.8,28.2,0,23.6,0z"/>
                </motion.svg>

                <motion.div
                    variants={farting_face_variants}
                    animate={farting_face_animation}
                    initial="hidden"
                    className="z-10"
                    onContextMenu={(e) => hideContextMenu(e)}
                >
                    <ScaledMotionImage src={farting_face} originalWidth={180} id="eyesClosed" alt=""
                                       className="self-center z-10 face_center touch-none select-none pointer-events-none"/>
                </motion.div>

                <motion.div
                    variants={default_variants}
                    animate={eyes_closed_animation}
                    initial="hidden"
                    className="z-10"
                    onContextMenu={(e) => hideContextMenu(e)}
                >
                    <ScaledMotionImage src={eyes_closed} originalWidth={180} id="eyesClosed" alt=""
                                       className="self-center z-10 face_center touch-none select-none pointer-events-none"/>
                </motion.div>

                <motion.div
                    variants={eyes_big_variants}
                    animate={eyes_big_animation}
                    initial="hidden"
                    className="z-10"
                    onContextMenu={(e) => hideContextMenu(e)}
                >
                    <ScaledMotionImage src={eyes_big} originalWidth={180} id="eyesOpen" alt=""
                                       className="self-center z-10 face_center touch-none select-none pointer-events-none"/>
                </motion.div>

                <motion.div
                    variants={thankful_variants}
                    animate={thankful_animation}
                    initial="hidden"
                    onContextMenu={(e) => hideContextMenu(e)}
                >
                    <ScaledMotionImage src={thankful_face} originalWidth={180} id="smileFluffy" alt=""
                                       className="self-center z-10 face_center touch-none select-none pointer-events-none"/>
                </motion.div>

                <motion.div

                    variants={smile_face_variants}
                    animate={smile_face_animation}
                    initial="visible"
                    className="face_center select-none"
                    onContextMenu={(e) => e.preventDefault()}
                >
                    <ScaledMotionImage src={smile_face} originalWidth={150} id="smileFluffy" alt=""
                                       className="self-center z-10 smile-face"/>
                </motion.div>

                <ScaledMotionImage originalWidth={600} src={nude_fluffy} id="nudeWorld" alt=""
                                   className="content-none self-center touch-none select-none pointer-events-none"/>

                <motion.div
                    animate={shadow_animation}
                    className="absolute select-none"
                    onContextMenu={(e) => hideContextMenu(e)}
                    style={{
                        bottom: 0,
                        scale: scale_shadow,
                        opacity: opacity_shadow
                    }}
                >
                    <ScaledMotionImage src={shadow_fluffy} originalWidth={800} id="shadow" alt=""
                                       className="self-center z-50 face_center touch-none select-none pointer-events-none"/>
                </motion.div>
            </motion.div>


        </motion.div>
    );
}

export default memo(NudeFluffy);
