import React, {memo, useEffect, useRef, useState} from "react";
import {motion, useAnimation, useMotionValue} from "framer-motion";
import useVH from "react-viewport-height";
import Lottie from "react-lottie-player";

import ScaledImage from "../../../components/layouts/ScaledImage";

import pink_fluffy from "../../../assets/img/02_scan/scan_pink@4x.png";

import childLaughSound from '../../../assets/sounds/childLaugh.mp3';

import Sound from "../../../components/sounds/Sound";
import bg02 from "../../../assets/img/03_play/play_bg-03.png";
import click from "../../../assets/sounds/click.mp3";
import pinkFluffyMusic from "../../../assets/sounds/pink_fluffy_music.mp3";
import {useNavigate} from "react-router-dom";
import back_button from "../../../assets/img/03_play/play_back.png";
import useWindowDimensions from "../../../hooks/useWindowDimensions";
import earPods from "../../../assets/img/03_play/play_ear-pods.png";
import leftEarPod from "../../../assets/img/03_play/leftEarPod.png";
import rightEarPod from "../../../assets/img/03_play/rightEarPod.png";
import play_star from "../../../assets/img/03_play/play_stars.png";
import music_lottie from "../../../assets/img/03_play/music pattern.json";
import ScaledMotionImage from "../../../components/layouts/ScaledMotionImage";
import chilled_face from "../../../assets/img/05_faces/chilled.svg";
import cool_face from "../../../assets/img/05_faces/cool.svg";
import sweet_face from "../../../assets/img/05_faces/sweet.svg";
import music_face from "../../../assets/img/05_faces/whistle.svg";
import outch_face from "../../../assets/img/05_faces/outch.svg";
import {AppData} from "../../../data/AppData";

type Props = {
    scaleFactor: number;
    fluffyName: string
};

function PinkFluffy({scaleFactor, fluffyName}: Props) {

    const vh = useVH();

    const childLaugh = new Sound(childLaughSound);
    const fluffyRect = useRef<HTMLDivElement>(null);
    const earPodsRect = useRef<HTMLDivElement>(null);
    const earPodsPreviousPosition = useRef<HTMLDivElement>(null);
    const earPodsInsideLeftPosition = useRef<HTMLDivElement>(null);
    const earPodsInsideRightPosition = useRef<HTMLDivElement>(null);
    const {windowHeight, windowWidth} = useWindowDimensions();

    let [isTaped, setTaped] = useState(1);

    const clickSound = new Sound(click);
    const musicSound = new Sound(pinkFluffyMusic);
    const navigate = useNavigate();

    const animationControl = useAnimation();
    const earPods_animation = useAnimation();
    const earPods_left_animation = useAnimation();
    const earPods_right_animation = useAnimation();
    const music_lottie_animation = useAnimation();
    const sweet_face_animation = useAnimation();
    const eyes_big_animation = useAnimation();
    const scared_face_animation = useAnimation();
    const chilled_face_animation = useAnimation();
    const cooled_face_animation = useAnimation();
    const music_face_animation = useAnimation();
    const outch_face_animation = useAnimation();
    const star_animation = useAnimation();

    const y_fluffy = useMotionValue(0);

    let [tap_timeStamp, setTapTime] = useState(0);
    let [tapCount, setTapCount] = useState(0);
    let [isDragging, setIsDrag] = useState(0);

    let [whichExpression, setWhichExpression] = useState('');
    let [isEarPodsActive, setEarPodsActive] = useState(0);
    let [previousEarPodsTop, setEPTop] = useState(0);
    let [previousEarPodsBottom, setEPBottom] = useState(0);
    let [previousEarPodsLeft, setEPLeft] = useState(0);
    let [previousEarPodsRight, setEPRight] = useState(0);
    let [previousEarPodsWidth, setEPWidth] = useState(0);
    let [previousEarPodsX, setEPX] = useState(0);
    let [previousEarPodsY, setEPY] = useState(0);


    const SWEET_FACE = "smile";
    const SCARED_FACE = "scared";
    const CHILLED_FACE = "chilled";
    const COOLED_FACE = "cool";
    const MUSIC_FACE = "music";
    const OUTCH_FACE = "outch";

    // variants for animations

    const star_variants = {
        visible: {opacity: [1, 0, 1], transition: {repeat: Infinity, duration: 2.5}},
        hidden: {opacity: 0, transition: {duration: 0}},
    }
    const earPods_variants = {
        visible: {opacity: 1, transition: {duration: 1}},
        hidden: {opacity: 0, transition: {duration: 0}},
    }
    const default_variants = {
        hidden: {opacity: 0, transition: {duration: 0}},
        visible: {opacity: 1, transition: {duration: 0}}
    }

    const nude_scan_variants = {
        visible: {
            opacity: 1,
            transition: {
                duration: 1.5
            },
            scale: 0.7
        },
        hidden: {opacity: 0, scale: 1.3},
    }

    animationControl.start("visible");
    earPods_animation.start("visible");
    star_animation.start("visible");

    // Functions

    function stopAllExpression() {
        console.log("stop exp: " + whichExpression);

        if (whichExpression === SWEET_FACE) {
            sweet_face_animation.start({opacity: 0, transition: {duration: 0}});
        } else if (whichExpression === SCARED_FACE) {
            scared_face_animation.start({opacity: 0, transition: {duration: 0}});
        } else if (whichExpression === CHILLED_FACE) {
            chilled_face_animation.start({opacity: 0, transition: {duration: 0}});
        } else if (whichExpression === COOLED_FACE) {
            cooled_face_animation.start({opacity: 0, transition: {duration: 0}});
        } else if (whichExpression === MUSIC_FACE) {
            music_face_animation.start({opacity: 0, transition: {duration: 0}});
        } else if (whichExpression === OUTCH_FACE) {
            outch_face_animation.start({opacity: 0, transition: {duration: 0}});
        } else {
            chilled_face_animation.start({opacity: 0, transition: {duration: 0}});
            sweet_face_animation.start({opacity: 0, transition: {duration: 0}});
            eyes_big_animation.start({opacity: 0, transition: {duration: 0}});
            cooled_face_animation.start({opacity: 0, transition: {duration: 0}});
            music_face_animation.start({opacity: 0, transition: {duration: 0}});
            outch_face_animation.start({opacity: 0, transition: {duration: 0}});
        }
    }

    // change face expression..
    async function changeFaceExpression(expression: string, wait: boolean) {
        stopAllExpression();

        console.log("which expression: " + expression);
        if (expression === SWEET_FACE) {
            whichExpression = SWEET_FACE;
            if (wait) {
                await sweet_face_animation.start({opacity: 1, transition: {duration: 0}});
            } else {
                sweet_face_animation.start({opacity: 1, transition: {duration: 0}});
            }
        } else if (expression === CHILLED_FACE) {
            whichExpression = CHILLED_FACE;
            if (wait) {
                await chilled_face_animation.start({opacity: 1, transition: {duration: 0}});
            } else {
                chilled_face_animation.start({opacity: 1, transition: {duration: 0}});
            }
        } else if (expression === SCARED_FACE) {
            whichExpression = SCARED_FACE;
            if (wait) {
                await scared_face_animation.start({opacity: 1, transition: {duration: 0}});
            } else {
                scared_face_animation.start({opacity: 1, transition: {duration: 0}});
            }
        } else if (expression === COOLED_FACE) {
            whichExpression = COOLED_FACE;
            if (wait) {
                await cooled_face_animation.start({opacity: 1, transition: {duration: 0}});
            } else {
                cooled_face_animation.start({opacity: 1, transition: {duration: 0}});
            }
        } else if (expression === MUSIC_FACE) {
            whichExpression = MUSIC_FACE;
            if (wait) {
                await music_face_animation.start({opacity: 1, transition: {duration: 0}});
            } else {
                music_face_animation.start({opacity: 1, transition: {duration: 0}});
            }
        } else if (expression === OUTCH_FACE) {
            whichExpression = OUTCH_FACE;
            if (wait) {
                await outch_face_animation.start({opacity: 1, transition: {duration: 0}});
            } else {
                outch_face_animation.start({opacity: 1, transition: {duration: 0}});
            }
        }

    }


    /*
    * TODO: Animation
    *
    * */

    function handleBackNavigation(e: any) {
        e.preventDefault();
        clickSound.play();
        setTimeout(() => {
            navigate("/", {replace: true});
        }, 200);
    }

    function handleOnTap(event: any, panInfo: any) {
        //console.log(panInfo);
        let compare = Math.abs(tap_timeStamp - event.timeStamp);
        if(compare < 300){
            tapCount = tapCount + 1;
        }else{
            tapCount = 1;
        }
        tap_timeStamp = event.timeStamp;

        if (tapCount > 4) {
            childLaugh.play();
        }
    }

    async function showEarPods() {
        changeFaceExpression(MUSIC_FACE, false);
        earPods_animation.start({pointerEvents: "none", transition: {duration: 0}});
        star_animation.start("hidden");

        //saving the previous position of Ear pods..
        let previousEarPodPosition = earPodsRect.current;
        if (previousEarPodPosition !== null) {
            previousEarPodsTop = previousEarPodPosition.getBoundingClientRect().top;
            previousEarPodsLeft = previousEarPodPosition.getBoundingClientRect().left;
            previousEarPodsRight = previousEarPodPosition.getBoundingClientRect().right;
            previousEarPodsWidth = previousEarPodPosition.getBoundingClientRect().width;
            previousEarPodsX = previousEarPodPosition.getBoundingClientRect().x;
            previousEarPodsY = previousEarPodPosition.getBoundingClientRect().y;
        }

        //getting the new ear pods position.
        let newTop, newLeft, newRight, newWidth;
        let earPodsRectInside = fluffyRect.current;
        if (earPodsRectInside !== null) {
            newTop = earPodsRectInside.getBoundingClientRect().top;
            newLeft = earPodsRectInside.getBoundingClientRect().left;
            newRight = earPodsRectInside.getBoundingClientRect().right;
            newWidth = earPodsRectInside.getBoundingClientRect().width;
        }


        await earPods_animation.start({
            top: newTop,
            left: 0,
            right: newRight,
            width: newWidth,
            opacity: 0,
            transition: {type: "spring", stiffness: 50, duration: 0}
        });

        earPods_left_animation.start({opacity: 1, transition: {duration :0}});
        earPods_right_animation.start({opacity: 1, transition: {duration :0}});
        music_lottie_animation.start("visible");
    }

    //listeners for the Events
    async function handleEarPodEvent(e: any) {
        e.preventDefault();
        if (isEarPodsActive !== 1) {
            musicSound.play();
            await showEarPods();
            isEarPodsActive = 1;
        }
    }

    async function removeEarPods(e: any) {
        e.preventDefault();

        if (isEarPodsActive === 1) {
            musicSound.stop();
            earPods_left_animation.start("hidden");
            earPods_right_animation.start("hidden");
            music_lottie_animation.start("hidden");
            changeFaceExpression(SWEET_FACE, false);

            await earPods_animation.start({
                width: previousEarPodsWidth,
                top: previousEarPodsTop,
                left: previousEarPodsLeft,
                right: previousEarPodsRight,
                opacity: 1,
                transition: {duration: 1, type: "spring", stiffness: 50}
            });
            earPods_animation.start({pointerEvents: "auto", transition: {duration: 0}});
            star_animation.start("visible");
            isEarPodsActive = 0;
        }
    }
    useEffect(() => {
        function updateFacesOnJumping() {
            if (isDragging) {
                const current_y = y_fluffy.get();
                if (current_y > -100) {
                    if (isEarPodsActive) {
                        changeFaceExpression(MUSIC_FACE, false);
                    } else {
                        changeFaceExpression(SWEET_FACE, false);
                    }
                }
            }
        }

        const unsubscribeY = y_fluffy.onChange(updateFacesOnJumping);

        return () => {
            unsubscribeY();
        }
    }, []);

    async function handleDragEventStop(e: any, info: any) {
        e.preventDefault();
        if(info.point.y < windowHeight){
            if(fluffyRect.current != null && fluffyRect.current.getBoundingClientRect().y < windowHeight){
                await animationControl.start({y: Math.round(10 * vh), transition:{type: "spring", stiffness: 6}})
            }
        }
        if (isEarPodsActive) {
            changeFaceExpression(MUSIC_FACE, false);
        } else {
            changeFaceExpression(SWEET_FACE, false);
        }
        isDragging = 0;
    }

    async function handleDragEventStart(e: any, info: any) {
        e.preventDefault();
        changeFaceExpression(CHILLED_FACE, false);
        isDragging = 1;
    }
    async function handleDragEvent(e: any, info: any) {
        e.preventDefault();
        // todo:  change face while touching the wall..
        if(isEarPodsActive){
            changeFaceExpression(OUTCH_FACE, false);
        }else{
            changeFaceExpression(COOLED_FACE, false);
        }

        // checking the edge touch
        console.log("pink fluffy info");
        console.log(info);

    }

    return (
        <motion.div
            animate={{
                opacity: 1,
                transition: {
                    duration: 1,
                    ease: "easeIn"
                }
            }}
            initial={{
                opacity: 0.2
            }}
            className="flex flex-col items-center justify-between w-screen text-center select-none"
            style={{
                backgroundImage: `url(${bg02})`,
                height: `${100 * vh}px`,
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
            }}>
            {/* back navigation */}
            <motion.div
                className="absolute z-10"
                style={{
                    left: 50 * scaleFactor,
                    top: 50 * scaleFactor,
                    WebkitTapHighlightColor: "transparent"
                }}
                onClick={(e) => handleBackNavigation(e)}
                whileTap={{scale: 0.8}}
            >
                <ScaledImage src={back_button} id="backButton" alt="back-button"
                             className="self-center touch-none select-none"/>
            </motion.div>

            {/* ear pods button */}
            <div
                ref={earPodsPreviousPosition}
                className="absolute z-20 touch-none select-none"
                style={{
                    top: 80 * scaleFactor,
                    right: 80 * scaleFactor
                }}
            >
            </div>

            {/*
             Ear pods button
            */}
            <motion.div
                ref={earPodsRect}
                className="absolute z-20"
                style={{
                    right: 80 * scaleFactor,
                    top: 80 * scaleFactor
                }}
                onClick={(e) => handleEarPodEvent(e)}
                variants={earPods_variants}
                animate={earPods_animation}
                initial="hidden"
                onContextMenu={(e) => e.preventDefault()}
            >
                <motion.div
                    className="absolute flower_star_01"
                    style={{
                        top: 20 * scaleFactor,
                        right: -20 * scaleFactor,
                    }}
                >
                    <ScaledImage src={earPods} originalWidth={150} id="alien" alt=""
                                 className="self-center touch-none select-none"/>
                </motion.div>


                <motion.div
                    className="absolute"
                    style={{
                        top: -50 * scaleFactor,
                        right: -50 * scaleFactor,
                    }}
                    variants={star_variants}
                    animate={star_animation}
                    initial="hidden"
                >
                    <ScaledImage src={play_star} id="star_pink" alt="" className="self-center touch-none select-none"/>
                </motion.div>

            </motion.div>


            <motion.div
                ref={fluffyRect}
                className="absolute flex justify-center w-full h-auto z-30 select-none"
                style={{
                    bottom: 50 * scaleFactor,
                    y: y_fluffy,
                }}
                drag
                dragConstraints={{
                    left: -Math.round(windowWidth / 2) + 80,
                    right: Math.round(windowWidth / 2) - 80,
                    top: -Math.round(60 * vh),
                    bottom: Math.round(10 * vh)
                }}
                dragTransition={{bounceStiffness: 1000, bounceDamping: 50}}
                dragElastic={0.2}
                variants={nude_scan_variants}
                animate={animationControl}
                onTap={(e, info) => handleOnTap(e, info)}
                onDragStart={(e, info) => handleDragEventStart(e, info)}
                onDragEnd={(e, info) => handleDragEventStop(e, info)}
                onDrag={(e, info) => handleDragEvent(e, info)}
                onContextMenu={(e) => removeEarPods(e)}
                initial="hidden"
            >
                { /* ear pods inside fluffy */}
                <motion.div
                    className="absolute z-50"
                    style={{
                        top: "30%",
                        left: 80 * scaleFactor,
                    }}
                    ref={earPodsInsideLeftPosition}
                    variants={default_variants}
                    animate={earPods_left_animation}
                    initial="hidden"
                    onContextMenu={(e) => removeEarPods(e)}
                    onClick={(e) => removeEarPods(e)}
                >
                    <ScaledMotionImage src={leftEarPod} originalWidth={100} id="earPodsInsides"
                                       alt="" className="self-center z-50 touch-none select-none"/>
                </motion.div>

                <motion.div
                    ref={earPodsInsideRightPosition}
                    className="absolute z-50"
                    style={{
                        top: "30%",
                        right: 100 * scaleFactor
                    }}
                    variants={default_variants}
                    animate={earPods_right_animation}
                    initial="hidden"
                    onContextMenu={(e) => removeEarPods(e)}
                    onClick={(e) => removeEarPods(e)}
                >
                    <ScaledMotionImage src={rightEarPod} originalWidth={100} id="earPodsInsides"
                                       alt="" className="self-center z-50 touch-none select-none"/>
                </motion.div>

                { /* music animation */}
                <motion.div
                    className="absolute z-50 bg-transparent alien-center w-100"
                    style={{
                        top: 50 * scaleFactor,
                        width: 600 * scaleFactor,
                        left: "50%",
                    }}
                    animate={music_lottie_animation}
                    variants={default_variants}
                    initial={"hidden"}
                >
                    <Lottie loop animationData={music_lottie} play className="self-center touch-none select-none"/>
                </motion.div>

                {/* all faces */}
                <motion.div
                    variants={default_variants}
                    animate={chilled_face_animation}
                    initial="hidden"
                    className="z-10"
                >
                    <ScaledMotionImage src={chilled_face} originalWidth={180} id="chilled_face" alt=""
                                       className="self-center z-10 face_center touch-none select-none"/>
                </motion.div>

                <motion.div
                    variants={default_variants}
                    animate={music_face_animation}
                    initial="hidden"
                    className="z-10"
                >
                    <ScaledMotionImage src={music_face} originalWidth={180} id="chilled_face" alt=""
                                       className="self-center z-10 face_center touch-none select-none"/>
                </motion.div>

                <motion.div
                    variants={default_variants}
                    animate={cooled_face_animation}
                    initial="hidden"
                    className="z-10"
                >
                    <ScaledMotionImage src={cool_face} originalWidth={180} id="cooled_face" alt=""
                                       className="self-center z-10 face_center touch-none select-none"/>
                </motion.div>

                <motion.div
                    variants={default_variants}
                    animate={outch_face_animation}
                    initial="hidden"
                    className="z-10"
                >
                    <ScaledMotionImage src={outch_face} originalWidth={180} id="outch_face" alt=""
                                       className="self-center z-10 face_center touch-none select-none"/>
                </motion.div>

                <motion.div
                    variants={default_variants}
                    animate={sweet_face_animation}
                    initial="visible"
                    className="face_center"
                >
                    <ScaledMotionImage src={sweet_face} originalWidth={150} id="sweetFluffy" alt=""
                                       className="self-center z-10 face_center touch-none select-none"/>
                </motion.div>

                <ScaledImage src={pink_fluffy} originalWidth={600} id="nudeWorld" alt="fluffy"
                                   className="self-center touch-none select-none pointer-events-none"/>
            </motion.div>
        </motion.div>
    );
}

export default memo(PinkFluffy);
