import React, {memo, useEffect, useRef, useState} from "react";
import {motion, useAnimation, useMotionValue} from "framer-motion";
import useVH from "react-viewport-height";

import ScaledImage from "../../../components/layouts/ScaledImage";

import green_fluffy from "../../../assets/img/02_scan/scan_green@4x.png";

import Sound from "../../../components/sounds/Sound";
import bg01 from "../../../assets/img/03_play/play_bg-02.png";
import back_button from "../../../assets/img/03_play/play_back.png";
import click from "../../../assets/sounds/click.mp3";
import ufo_landing from "../../../assets/sounds/ufo_landing.mp3";
import ufo_hovering from "../../../assets/sounds/ufo_hovering.mp3";
import {useNavigate} from "react-router-dom";
import useWindowDimensions from "../../../hooks/useWindowDimensions";
import play_star from "../../../assets/img/03_play/play_stars.png";
import scared_face from "../../../assets/img/05_faces/scared.svg";
import alienAntenna from "../../../assets/img/02_scan/scan_alien.png";
import ufo from "../../../assets/img/02_scan/ufo/floating-ufo.png";
import alienAntennaGlow from "../../../assets/img/02_scan/scan_alien-light.png";
import ScaledMotionImage from "../../../components/layouts/ScaledMotionImage";
import chilled_face from "../../../assets/img/05_faces/chilled.svg";
import cool_face from "../../../assets/img/05_faces/cool.svg";
import smile_face from "../../../assets/img/05_faces/smile.svg";

type Props = {
    scaleFactor: number;
    fluffyName: string
};


function GreenFluffy({scaleFactor, fluffyName}: Props) {

    const vh = useVH();

    const animationControl = useAnimation();
    const constraintsRef = useRef<HTMLDivElement>(null);
    const smile_face_animation = useAnimation();
    const eyes_big_animation = useAnimation();
    const scared_face_animation = useAnimation();
    const chilled_face_animation = useAnimation();
    const cooled_face_animation = useAnimation();
    const star_animation = useAnimation();
    const alienAntenna_animation = useAnimation();
    const alienAntennaGlow_animation = useAnimation();
    const alienAntennaGlowOff_animation = useAnimation();
    const ufo_animation = useAnimation();
    const alienAntennaRect = useRef<HTMLDivElement>(null);
    const ufoRect = useRef<HTMLDivElement>(null);
    const fluffyRef = useRef<HTMLDivElement>(null);
    const previousAlienRect = useRef<HTMLDivElement>(null);
    const alienAntennaOriginElement = useRef<HTMLDivElement>(null);
    let [alienAntennaOriginWidth, setAlienAntennaOriginWidth] = useState(0);
    let [alienAntennaOriginHeight, setAlienAntennaOriginHeight] = useState(0);
    let [alienAntennaOriginTop, setAlienAntennaOriginTop] = useState(0);
    let [alienAntennaOriginLeft, setAlienAntennaOriginLeft] = useState(0);
    let [alienAntennaOriginRight, setAlienAntennaOriginRight] = useState(0);
    let [alienAntennaOriginBottom, setAlienAntennaOriginBottom] = useState(0);

    const {windowHeight, windowWidth} = useWindowDimensions();
    let [whichExpression, setWhichExpression] = useState('');
    let [isAlienAntennaActive, setAlienActive] = useState(0);
    let [isUFOActive, setUFOActive] = useState(0);
    let [ufoTapTimeStamp, setUfoTapTime] = useState(0);
    let [ufoTapCount, setUfoTapCount] = useState(0);
    let [ufoDucked, setUfoDucked] = useState(0);

    const y_fluffy = useMotionValue(0);

    const clickSound = new Sound(click);
    const ufoLanding = new Sound(ufo_landing);
    const ufoHovering = new Sound(ufo_hovering);
    const navigate = useNavigate();

    const SMILE_FACE = "smile";
    const EYES_BIG = "eyes_big";
    const SCARED_FACE = "scared";
    const CHILLED_FACE = "chilled";
    const COOLED_FACE = "cool";
    const alienWidth = 500;

    /*
    * TODO: Animation
    *
    * */

    useEffect(() => {
        const alienOriginRect = alienAntennaOriginElement.current;
        if (alienOriginRect != null && alienAntennaOriginWidth !== 0 && alienAntennaOriginHeight !== 0) {
            alienAntennaOriginWidth = alienOriginRect.getBoundingClientRect().width;
            alienAntennaOriginHeight = alienOriginRect.getBoundingClientRect().height;
            alienAntennaOriginTop = alienOriginRect.getBoundingClientRect().top;
            alienAntennaOriginBottom = alienOriginRect.getBoundingClientRect().bottom;
            alienAntennaOriginLeft = alienOriginRect.getBoundingClientRect().left;
            alienAntennaOriginRight = alienOriginRect.getBoundingClientRect().right;
        }
    }, [alienAntennaOriginElement.current]);

    const fluffy_variants = {
        visible: {opacity: 1, transition: {duration: 1.5}, scale: 0.7},
        hidden: {opacity: 0, scale: 1.3},
    }

    const alien_variants = {
        visible: {opacity: 1, transition: {duration: 1}},
        hidden: {opacity: 0, transition: {duration: 0}},
    }
    const alien_variants_2 = {
        hidden: {opacity: 0, transition: {duration: 0}},
        visible: {opacity: 1, transition: {duration: 0}}
    }
    const alien_variants_3 = {
        hidden: {scale: 0.73, opacity: 0, transition: {duration: 0}},
        visible: {scale: 0.73, opacity: 1, transition: {duration: 0}}
    }
    const ufo_variants = {
        hidden: {opacity: 0, scale: 0.5, transition: {duration: 0}},
        visible: {opacity: 1, transition: {duration: 0}}
    }
    const star_variants = {
        visible: {opacity: [1, 0, 1], transition: {repeat: Infinity, type:"reverse", duration: 2.5}},
        hidden: {opacity: 0, transition: {duration: 0}},
    }
    const smile_face_variants = {
        visible: {opacity: 1, transition: {duration: 0,}},
        hidden: {opacity: 0, transition: {duration: 0}},
    }
    const scared_face_variants = {
        visible: {opacity: 1, transition: {duration: 0,}},
        hidden: {opacity: 0, transition: {duration: 0}},
    }
    const default_variants = {
        hidden: {opacity: 0, transition: {duration: 0}},
        visible: {opacity: 1, transition: {duration: 0}}
    }


    animationControl.start("visible");
    alienAntenna_animation.start("visible");
    star_animation.start("visible");

    function handleBackNavigation(e: any) {
        e.preventDefault();
        clickSound.play();
        setTimeout(() => {
            navigate("/", {replace: true});
        }, 200);
    }

    function stopAllExpression() {
        console.log("stop exp: " + whichExpression);

        if (whichExpression === SMILE_FACE) {
            smile_face_animation.start({opacity: 0, transition: {duration: 0}});
        } else if (whichExpression === SCARED_FACE) {
            scared_face_animation.start({opacity: 0, transition: {duration: 0}});
        } else if (whichExpression === CHILLED_FACE) {
            chilled_face_animation.start({opacity: 0, transition: {duration: 0}});
        } else if (whichExpression === COOLED_FACE) {
            cooled_face_animation.start({opacity: 0, transition: {duration: 0}});
        } else {
            chilled_face_animation.start({opacity: 0, transition: {duration: 0}});
            smile_face_animation.start({opacity: 0, transition: {duration: 0}});
            eyes_big_animation.start({opacity: 0, transition: {duration: 0}});
            cooled_face_animation.start({opacity: 0, transition: {duration: 0}});
        }
    }

    // change face expression..
    async function changeFaceExpression(expression: string, wait: boolean) {
        stopAllExpression();

        console.log("which expression: " + expression);
        if (expression === SMILE_FACE) {
            whichExpression = SMILE_FACE;
            if (wait) {
                await smile_face_animation.start({opacity: 1, transition: {duration: 0}});
            } else {
                smile_face_animation.start({opacity: 1, transition: {duration: 0}});
            }
        } else if (expression === CHILLED_FACE) {
            whichExpression = CHILLED_FACE;
            if (wait) {
                await chilled_face_animation.start({opacity: 1, transition: {duration: 0}});
            } else {
                chilled_face_animation.start({opacity: 1, transition: {duration: 0}});
            }
        } else if (expression === SCARED_FACE) {
            whichExpression = SCARED_FACE;
            if (wait) {
                await scared_face_animation.start({opacity: 1, transition: {duration: 0}});
            } else {
                scared_face_animation.start({opacity: 1, transition: {duration: 0}});
            }
        } else if (expression === COOLED_FACE) {
            whichExpression = COOLED_FACE;
            if (wait) {
                await cooled_face_animation.start({opacity: 1, transition: {duration: 0}});
            } else {
                cooled_face_animation.start({opacity: 1, transition: {duration: 0}});
            }
        }

    }

    // TODO: need to make the function for getting rect elements

    type Dimensions = {
        top: number;
        bottom: number;
        left: number;
        right: number;
        width: number;
        height: number;
    };

    async function showingAlienAntennaAnimation() {
        star_animation.start("hidden");
        changeFaceExpression(SCARED_FACE, true);

        alienAntenna_animation.start({pointerEvents: "none", transition: {duration: 0}});
        alienAntennaGlow_animation.start({pointerEvents: "auto", transition: {duration: 0}});
        alienAntennaGlowOff_animation.start({pointerEvents: "auto", transition: {duration: 0}});

        isAlienAntennaActive = 1;

        //get the height width of flower band situated inside fluffy
        let bandRight, bandTop, bandLeft, bandBottom, bandHeight, bandWidth;
        let alienAntennaElement = alienAntennaRect.current;
        if (alienAntennaElement !== null) {
            bandTop = alienAntennaElement.getBoundingClientRect().top;
            bandBottom = alienAntennaElement.getBoundingClientRect().bottom;
            bandLeft = alienAntennaElement.getBoundingClientRect().left;
            bandRight = alienAntennaElement.getBoundingClientRect().right;
            bandWidth = alienAntennaElement.getBoundingClientRect().width;
            bandHeight = alienAntennaElement.getBoundingClientRect().height;
        }

        await alienAntenna_animation.start({
            left: bandLeft,
            top: bandTop,
            right: bandRight,
            bottom: bandBottom,
            width: bandWidth,
            height: bandHeight,
            opacity: [0.9, 0.7, 0.3, 0],
            transition: {
                type: "spring",
                stiffness: 70,
                duration: 0.8,
            }
        });


        alienAntenna_animation.start({pointerEvents: "none", transition: {duration: 0}});

        alienAntennaGlowOff_animation.start({
            opacity: 1,
            transition: {
                duration: 0,
            }
        });

        await alienAntennaGlow_animation.start({
            opacity: [0, 1, 1.5],
            transition: {
                type: "reverse",
                repeat: Infinity,
                duration: 2.5,
                ease: "easeIn"
            }
        });
    }
    useEffect(() => {
        function updateFacesOnJumping() {
            if (!ufoDucked && isAlienAntennaActive  && isUFOActive && ufoRect.current != null && fluffyRef.current != null) {
                const current_y = Math.abs(y_fluffy.get());
                if(current_y > (ufoRect.current.getBoundingClientRect().y / 2 + 100)){
                    ufoDucked = 1;
                    removeUFO(false);
                }
            }
        }

        const unsubscribeY = y_fluffy.onChange(updateFacesOnJumping);

        return () => {
            unsubscribeY();
        }
    }, []);


    async function removeUFO(takeFluffy: boolean) {
        if (isUFOActive === 1) {
            ufoHovering.play();
            alienAntenna_animation.start({pointerEvents: "none", transition: {duration: 0}});
            ufo_animation.start({pointerEvents: "none", transition: {duration: 0}});

            if(takeFluffy){
                animationControl.start({pointerEvents: "none", transition: {duration: 0}});
                await animationControl.start({
                    y: -100,
                    opacity: 0,
                    scale: 0.3,
                    pointerEvents: "none",
                    transition: {delay: 1, duration: 1}
                });
            }

            ufo_animation.start({
                marginTop: 80,
                transition: {type: "spring", stiffness: 30, duration: 0.5}
            });

            if (ufoRect.current != null) {
                await ufo_animation.start({
                    left: ufoRect.current.getBoundingClientRect().left,
                    width: ufoRect.current.getBoundingClientRect().width,
                    top: ufoRect.current.getBoundingClientRect().top - ufoRect.current.getBoundingClientRect().width - 100,
                    opacity: [1, 0],
                    scale: [1, 0],
                    transition: {duration: 2.5}
                });
            }
            animationControl.start({pointerEvents: "auto", transition: {duration: 0}});
            isUFOActive = 0;
            ufoDucked = 0;
        }
    }

    async function hidingAlienAntennaAnimation() {
        changeFaceExpression(SMILE_FACE, true);
        if (isUFOActive === 1) {
            removeUFO(false);
        }
        //making alien antenna not clickable..
        alienAntenna_animation.start({pointerEvents: "auto", transition: {duration: 0}});
        alienAntennaGlow_animation.start({pointerEvents: "none", transition: {duration: 0}});
        alienAntennaGlowOff_animation.start({pointerEvents: "none", transition: {duration: 0}});

        alienAntennaGlow_animation.start({opacity: 0, transition: {duration: 0,}});
        alienAntennaGlowOff_animation.start({opacity: 0, transition: {duration: 0}});

        isAlienAntennaActive = 0;

        // getting current position of Alien Antenna.
        if (alienAntennaRect.current != null) {
            await alienAntenna_animation.start({
                top: alienAntennaRect.current.getBoundingClientRect().top,
                left: alienAntennaRect.current.getBoundingClientRect().left,
                width: alienAntennaRect.current.getBoundingClientRect().width,
                height: alienAntennaRect.current.getBoundingClientRect().height,
                opacity: 0,
                transition: {
                    duration: 0
                }
            });
        }

        if (previousAlienRect.current != null) {
            await alienAntenna_animation.start({
                top: previousAlienRect.current.getBoundingClientRect().top,
                left: previousAlienRect.current.getBoundingClientRect().left,
                height: previousAlienRect.current.getBoundingClientRect().height,
                width: previousAlienRect.current.getBoundingClientRect().width,
                opacity: 1,
                transition: {
                    duration: 1
                }
            });
        }

        star_animation.start("visible");

        // TODO: fix the fade out position
    }

    //Animation Area
    async function callTheUFOAnimation() {
        isUFOActive = 1;
        changeFaceExpression(SCARED_FACE, true);

        if (alienAntennaRect.current != null) {
            await ufo_animation.start({
                top: alienAntennaRect.current.getBoundingClientRect().top - 100,
                left: alienAntennaRect.current.getBoundingClientRect().left,
                right: alienAntennaRect.current.getBoundingClientRect().right,
                bottom: alienAntennaRect.current.getBoundingClientRect().bottom,
                width: alienAntennaRect.current.getBoundingClientRect().width,
                opacity: [0, 1],
                scale: [0, 1],
                transition: {duration: 2}
            });
        }

        if (alienAntennaRect.current != null) {
            await ufo_animation.start({
                top: alienAntennaRect.current.getBoundingClientRect().top - 60,
                transition: {type: "spring", stiffness: 30, duration: 1}
            });
        }
    }

    // End of Animations -----


    // Event listener's  ..........................
    async function handleAlienAntennaEvent(e: any) {
        e.preventDefault();

        if (isAlienAntennaActive !== 1) {
            await showingAlienAntennaAnimation();
        }

    }

    async function handleDoubleTapOnFluffy(e: any) {
        e.preventDefault();
    }

    async function handleAntennaTap(e: any) {
        e.preventDefault();
        let compare = Math.abs(ufoTapTimeStamp - e.timeStamp);
        if (compare < 300) {
            ufoTapCount = ufoTapCount + 1;
        } else {
            ufoTapCount = 1;
        }
        ufoTapTimeStamp = e.timeStamp;

        if (ufoTapCount === 2) {
            if (isAlienAntennaActive === 1 && isUFOActive === 1) {
                await removeUFO(true);
            }
        } else if (ufoTapCount === 1) {
            if (isAlienAntennaActive === 1 && isUFOActive !== 1) {
                ufoLanding.play();
                await callTheUFOAnimation();
            }
        }
    }

    async function removeAntenna(e: any) {
        e.preventDefault();
        if (isAlienAntennaActive === 1) {
            await hidingAlienAntennaAnimation();
        }
    }

    async function handleDragEventStop(e: any, info: any) {
        e.preventDefault();
        changeFaceExpression(SMILE_FACE, false);
    }

    async function handleDragEventStart(e: any, info: any) {
        e.preventDefault();
        changeFaceExpression(CHILLED_FACE, false);
    }

    async function handleDragEvent(e: any, info: any) {
        e.preventDefault();
        // todo:  change face while touching the wall..
    }

    return (
        <motion.div
            animate={{
                opacity: 1,
                transition: {
                    duration: 1,
                    ease: "easeIn"
                }
            }}
            initial={{
                opacity: 0.2
            }}
            className="flex flex-col items-center justify-between w-screen text-center select-none"
            style={{
                backgroundImage: `url(${bg01})`,
                height: `${100 * vh}px`,
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
            }}>
            {/* back navigation */}
            <motion.div
                className="absolute z-10 select-none touch-none"
                style={{
                    left: 50 * scaleFactor,
                    top: 50 * scaleFactor,
                    WebkitTapHighlightColor: "transparent"
                }}
                onClick={(e) => handleBackNavigation(e)}
                whileTap={{scale: 0.8}}
            >
                <ScaledImage src={back_button} id="backButton" alt=""
                             className="self-center touch-none select-none"/>

            </motion.div>

            <div
                ref={previousAlienRect}
                className="absolute z-20 touch-none select-none"
                style={{
                    top: 80 * scaleFactor,
                    right: 80 * scaleFactor
                }}
            >
            </div>

            {/*
             alien button
            */}
            <motion.div
                ref={alienAntennaOriginElement}
                className="absolute z-20 select-none"
                style={{
                    right: 80 * scaleFactor,
                    top: 80 * scaleFactor
                }}
                onClick={(e) => handleAlienAntennaEvent(e)}
                variants={alien_variants}
                animate={alienAntenna_animation}
                initial="hidden"
                onContextMenu={(e) => e.preventDefault()}
            >
                <motion.div
                    className="absolute touch-none select-none"
                    style={{
                        top: 20 * scaleFactor,
                        right: -20 * scaleFactor,
                    }}
                >
                    <ScaledImage src={alienAntenna} originalWidth={150} id="alien" alt=""
                                 className="self-center touch-none select-none"/>
                </motion.div>


                <motion.div
                    className="absolute touch-none select-none"
                    style={{
                        top: -50 * scaleFactor,
                        right: -50 * scaleFactor,
                    }}
                    variants={star_variants}
                    animate={star_animation}
                    initial="hidden"
                >
                    <ScaledImage src={play_star} id="star_01" alt="" className="self-center touch-none select-none"/>
                </motion.div>

            </motion.div>


            <motion.div
                ref={ufoRect}
                className="absolute bg-transparent alien-center pointer-events-none select-none"
                variants={ufo_variants}
                animate={ufo_animation}
                style={{
                    top: -100,
                }}
                initial="hidden"
            >
                <ScaledMotionImage src={ufo} originalWidth={700} id="ufo" alt=""
                                   className="self-center z-20 alien-center select-none touch-none select-none pointer-events-none"/>
            </motion.div>


            {/* fluffy element */}
            <motion.div
                ref={fluffyRef}
                className="absolute flex justify-center w-full h-auto z-20 select-none"
                style={{
                    bottom: 50 * scaleFactor,
                    y: y_fluffy
                }}
                drag
                dragConstraints={{
                    left: -Math.round(windowWidth / 2) + 80,
                    right: Math.round(windowWidth / 2) - 80,
                    top: -Math.round(60 * vh),
                    bottom: Math.round(10 * vh)
                }}
                dragTransition={{bounceStiffness: 600, bounceDamping: 50}}
                dragElastic={0.2}
                variants={fluffy_variants}
                animate={animationControl}
                initial="hidden"
                onDragEnd={(e, info) => handleDragEventStop(e, info)}
                onDragStart={(e, info) => handleDragEventStart(e, info)}
                onDrag={(e, info) => handleDragEvent(e, info)}
                onDoubleClick={(e) => handleDoubleTapOnFluffy(e)}
                onContextMenu={(e) => e.preventDefault()}
            >
                { /* alien button for the animation part */}
                <div
                    className="absolute z-50 bg-transparent alien-center touch-none select-none pointer-events-none"
                    style={{
                        top: 50 * scaleFactor,
                        marginLeft: 5
                    }}
                    onClick={(e) => handleAntennaTap(e)}
                >
                    <motion.div
                        className="touch-none select-none pointer-events-none"
                        ref={alienAntennaRect}
                        id="alienGlow"
                        variants={alien_variants_2}
                        animate={alienAntennaGlow_animation}
                        initial="hidden"
                        onContextMenu={(e) => removeAntenna(e)}
                    >
                        <ScaledImage src={alienAntennaGlow} originalWidth={alienWidth} id="alien_glow"
                                           alt="" className="self-center z-50 alien-center touch-none select-none"/>
                    </motion.div>

                    { /* */}
                    <motion.div
                        className="touch-none select-none pointer-events-none"
                        variants={alien_variants_3}
                        animate={alienAntennaGlowOff_animation}
                        initial="hidden"
                        onContextMenu={(e) => removeAntenna(e)}
                    >
                        <ScaledImage src={alienAntenna} originalWidth={alienWidth} id="alien_glow2"
                                           alt="" className="self-center z-60 alien-center touch-none select-none"/>
                    </motion.div>
                </div>


                <motion.div
                    variants={scared_face_variants}
                    animate={scared_face_animation}
                    initial="hidden"
                    className="z-10 select-none"
                >
                    <ScaledMotionImage src={scared_face} originalWidth={180} id="eyesScared" alt=""
                                       className="self-center z-10 face_center touch-none select-none pointer-events-none"/>
                </motion.div>

                <motion.div
                    variants={default_variants}
                    animate={chilled_face_animation}
                    initial="hidden"
                    className="z-10"
                >
                    <ScaledMotionImage src={chilled_face} originalWidth={180} id="chilled_face" alt=""
                                       className="self-center z-10 face_center touch-none select-none pointer-events-none"/>
                </motion.div>

                <motion.div
                    variants={default_variants}
                    animate={cooled_face_animation}
                    initial="hidden"
                    className="z-10"
                >
                    <ScaledMotionImage src={cool_face} originalWidth={180} id="cooled_face" alt=""
                                       className="self-center z-10 face_center touch-none select-none pointer-events-none"/>
                </motion.div>

                <motion.div
                    variants={smile_face_variants}
                    animate={smile_face_animation}
                    initial="visible"
                    className="face_center"
                >
                    <ScaledMotionImage src={smile_face} originalWidth={150} id="smileFluffy" alt=""
                                       className="self-center z-10 face_center touch-none select-none pointer-events-none"/>
                </motion.div>

                <ScaledImage src={green_fluffy} originalWidth={600} id="greenWorld" alt=""
                             className="self-center touch-none select-none pointer-events-none"/>
            </motion.div>
        </motion.div>
    );
}

export default memo(GreenFluffy);
